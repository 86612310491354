import axios from "axios";
import { useQuery } from "react-query";
import { apiRouteTest } from "../utils/api";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

type GetDefTableType = {
  client_id: number;
  id: number;
  invoice_date: string;
  invoice_total: number;
  periods: number;
};

function useGetDeferredRevenue() {
  const { data, isLoading } = useQuery(["getDeferredRevenue"], () =>
    axios.get<GetDefTableType[]>(`${apiRouteTest}/deferred-revenue/`),
  );

  return { data, isLoading };
}

export { useGetDeferredRevenue };
