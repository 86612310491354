import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { apiRouteTest } from "../utils/api";
import { Context } from "../utils/commonFunctions";

export type QBOType = {
  code: string;
  realm_id: string;
  state: string;
  client_secret: string;
  client_id: string;
};

type RespOBO = {
  tokens: {
    refresh_token: string;
    id_token: string;
    access_token: string;
  };
  client_id: string;
  client_secret: string;
  code: string;
  created_at: string;
  id: number;
  realm_id: string;
  state: string;
  updated_at: string;
};

function usePostGetTokens() {
  const context = useContext(Context);
  const history = useHistory();
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: QBOType) => axios.post<RespOBO>(`${apiRouteTest}/get-tokens/`, data),
    {
      onSuccess: res => {
        localStorage.setItem("access_token", res.data.tokens.access_token);
        localStorage.setItem("id_token", res.data.tokens.id_token);
        localStorage.setItem("refresh_token", res.data.tokens.refresh_token);
        history.push("/home");
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostGetTokens };
