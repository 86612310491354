import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

export type DeteteData = {
  type:
    | "deferred-revenue"
    | "accrued-interest"
    | "depreciation-amortization"
    | "prepaid-expenses";
  id: number;
};

function useDelFieldTable(onClose: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: DeteteData) =>
      axios.delete(`${apiRouteTest}/${data.type}/${data.id}/`),
    {
      onSuccess: (res, rej) => {
        queryClient.invalidateQueries(
          rej.type === "accrued-interest"
            ? "getAccruedInterest"
            : rej.type === "deferred-revenue"
            ? "getDeferredRevenue"
            : rej.type === "depreciation-amortization"
            ? "getDepreciation"
            : rej.type === "prepaid-expenses"
            ? "getPrepaidExpenses"
            : "",
        );
        onClose();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { useDelFieldTable };
