import { Formik } from "formik";
import styled from "styled-components";
import validationModalAddUserPrepaidExpenses from "./validationModalAddUserPrepaidExpenses";
import { CustomLoader } from "../../../Loader";
import { usePostPrepaidExpenses } from "../../../../hooks/usePostPrepaidExpenses";
import { useCheckAuth } from "../../../../hooks/useCheckAuth";
import { useDecodeJwt } from "../../../../hooks/useDecodeJwt";
import { useState } from "react";
import { customStylesForSettingsSelect } from "../../../../utils/commonFunctions";
import { useGetCategory } from "../../../../hooks/useGetCategoryDeprecation";
import Select from "react-select";

type PropsType = {
  onClose: () => void;
};

export function ModalAddUserPrepaidExpenses({ onClose }: PropsType) {
  const { mutate: addUserPrepaidExpenses, isLoading } =
    usePostPrepaidExpenses(onClose);

  const { data: authToken } = useCheckAuth();
  const { data: userData } = useDecodeJwt(authToken || "");

  const { data: dataCategory } = useGetCategory();

  const [initialValueSelect, setInitialValueSelect] = useState<{
    name: string;
    id: number;
  } | null>(null);

  const SingleValue = (props: any) => (
    <CustomOption>
      {initialValueSelect ? <span>{initialValueSelect.name}</span> : ""}
    </CustomOption>
  );

  const filterCat = dataCategory?.data.filter(
    i =>
      i.name === "Insurance" ||
      i.name === "Software and Computer Expense" ||
      i.name === "Digital Marketing Expense" ||
      i.name === "Miscellaneous Marketing Expense" ||
      i.name === "Sales and Marketing Expenses" ||
      i.name === "Prepaid Marketing" ||
      i.name === "Sales and Marketing" ||
      i.name === "Sales and Marketing - Payroll",
  );

  return (
    <ModalContent>
      <h3>Add new item</h3>
      <Formik
        initialValues={{
          category: 0,
          purchase_date: "",
          periods: "",
          purchase_price: "",
        }}
        validationSchema={validationModalAddUserPrepaidExpenses}
        onSubmit={(values, { setSubmitting }) => {
          addUserPrepaidExpenses({
            category: values.category,
            purchase_date: values.purchase_date,
            periods: +values.periods,
            purchase_price: +values.purchase_price,
            client_id: userData?.user_id || 0,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Category</TitleIpnut>
              <SelectWrapper>
                <SelectStyled
                  onChange={(e: any) => {
                    setInitialValueSelect({
                      name: e?.name,
                      id: e?.id,
                    });
                    setFieldValue("category", e?.id);
                  }}
                  components={{ SingleValue }}
                  options={filterCat}
                  styles={customStylesForSettingsSelect}
                  placeholder="Select item"
                  value={initialValueSelect}
                  isSearchable={false}
                  formatOptionLabel={(lang: any) => <span>{lang.name}</span>}
                  error={!initialValueSelect && touched.category}
                />
              </SelectWrapper>

              <TitleIpnut>Purchase date</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="YYYY-MM-DD"
                  type="date"
                  name="purchase_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purchase_date}
                  error={!!errors.purchase_date && touched.purchase_date}
                />
              </InputWrapperModalToken>

              <TitleIpnut>{"Periods (Months)"}</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter periods"
                  type="text"
                  name="periods"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.periods}
                  error={!!errors.periods && touched.periods}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Purchase price</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter purchase price"
                  type="text"
                  name="purchase_price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purchase_price}
                  error={!!errors.purchase_price && touched.purchase_price}
                />
              </InputWrapperModalToken>

              {isLoading ? (
                <CustomLoader size={40} />
              ) : (
                <ModalButton disabled={isLoading}>
                  <button type="submit">Confirm</button>
                </ModalButton>
              )}
            </form>
          </ItemModal>
        )}
      </Formik>
      <CloseMod onClick={() => onClose()}>x</CloseMod>
    </ModalContent>
  );
}

const SelectStyled = styled(Select)<{
  error?: boolean;
}>`
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  border-radius: 6px;
`;

const SelectWrapper = styled.div`
  height: 46px;
  color: #fff;
  border: none;
  background: #161d24;
  border-radius: 6px;
  margin: 9px 0 20px 0;
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  span {
    color: white;
  }
`;

const CloseMod = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #616d7a;
  font-size: 20px;
  :hover {
    cursor: pointer;
  }
`;

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 44px;
  background: #161d24;
  border-radius: 6px;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    :hover {
      cursor: pointer;
    }
  }
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  margin-top: 34px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;

    ${props => (props.disabled ? `opacity: 0.5; cursor: no-drop` : ``)};

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 430px;
  height: 520px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
