import axios from "axios";
import { useQuery } from "react-query";
import { apiRouteTest } from "../utils/api";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

function useGetPrepaidExpenses() {
  const { data, isLoading } = useQuery(["getPrepaidExpenses"], () =>
    axios.get(`${apiRouteTest}/prepaid-expenses/`),
  );

  return { data, isLoading };
}

export { useGetPrepaidExpenses };
