import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest } from "../utils/api";
import { Context } from "../utils/commonFunctions";

export type MappingQBOType = {
  access_token: string;
};

type RespMappingOBO = {};

function usePostMappingQBO() {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: MappingQBOType) =>
      axios.post<RespMappingOBO>(`${apiRouteTest}/qbo-accounts/`, data),
    {
      onSuccess: () => {
        context?.setMessage("Successfully processed");
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostMappingQBO };
