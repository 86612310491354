import { Formik } from "formik";
import styled from "styled-components";
import validationModalAddUserDepreciation from "./validationModalAddUserDepreciation";
import { CustomLoader } from "../../../Loader";
import { useCheckAuth } from "../../../../hooks/useCheckAuth";
import { useDecodeJwt } from "../../../../hooks/useDecodeJwt";
import { customStylesForSettingsSelect } from "../../../../utils/commonFunctions";
import { useState } from "react";
import Select from "react-select";
import { useGetItems } from "../../../../hooks/useGetItemsDeprecation";
import { useGetCategory } from "../../../../hooks/useGetCategoryDeprecation";
import { usePutDepreciation } from "../../../../hooks/usePutDepreciation";

type PropsModal = {
  closeModal: () => void;
  item: number;
  category: number;
  purchase_price: number;
  useful_life: number;
  purchase_date: string;
  client_id: number;
  id: number;
};

export function ModalUpdateDepreciation({
  closeModal,
  item,
  category,
  purchase_price,
  useful_life,
  purchase_date,
  client_id,
  id,
}: PropsModal) {
  const { mutate: addUpdateDepreciation, isLoading } =
    usePutDepreciation(closeModal);

  const { data: authToken } = useCheckAuth();
  const { data: userData } = useDecodeJwt(authToken || "");

  const { data: dataCategory } = useGetCategory();
  const { data: dataItems } = useGetItems();

  const iniCat = dataCategory?.data.filter(i => i.id === category);
  const iniItem = dataItems?.data.filter(i => i.id === item);

  const [initialValueSelect, setInitialValueSelect] = useState<{
    name: string;
    id: number;
  } | null>({
    name: iniItem ? iniItem[0].name : "",
    id: iniItem ? iniItem[0].id : 0,
  });

  const [initialValueCategory, setInitialValueCategory] = useState<{
    name: string;
    id: number;
  } | null>({
    name: iniCat ? iniCat[0].name : "",
    id: iniCat ? iniCat[0].id : 0,
  });

  const SingleValue = (props: any) => (
    <CustomOption>
      {initialValueSelect ? <span>{initialValueSelect.name}</span> : ""}
    </CustomOption>
  );

  const itemsFilter = dataItems?.data.filter(
    i => i.category === initialValueCategory?.id,
  );

  return (
    <ModalContent>
      <h3>Update item</h3>
      <Formik
        initialValues={{
          item,
          category,
          purchase_price,
          useful_life,
          purchase_date,
        }}
        validationSchema={validationModalAddUserDepreciation}
        onSubmit={(values, { setSubmitting }) => {
          addUpdateDepreciation({
            item: +values.item,
            category: values.category || 0,
            purchase_price: +values.purchase_price,
            useful_life: +values.useful_life,
            purchase_date: values.purchase_date,
            client_id: userData?.user_id || 0,
            id,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Category</TitleIpnut>
              <SelectWrapper>
                <SelectStyled
                  onChange={(e: any) => {
                    setInitialValueCategory({
                      name: e?.name,
                      id: e?.id,
                    });
                    setFieldValue("category", e?.id);
                  }}
                  options={dataCategory?.data}
                  styles={customStylesForSettingsSelect}
                  placeholder="Select category"
                  value={initialValueCategory}
                  formatOptionLabel={(lang: any) => <span>{lang.name}</span>}
                  error={!initialValueCategory && touched.category}
                />
              </SelectWrapper>

              {initialValueCategory && (
                <>
                  <TitleIpnut>Item</TitleIpnut>
                  <SelectWrapper>
                    <SelectStyled
                      onChange={(e: any) => {
                        setInitialValueSelect({
                          name: e?.name,
                          id: e?.id,
                        });
                        setFieldValue("item", e?.id);
                      }}
                      components={{ SingleValue }}
                      options={itemsFilter}
                      styles={customStylesForSettingsSelect}
                      placeholder="Select item"
                      value={initialValueSelect}
                      isSearchable={false}
                      formatOptionLabel={(lang: any) => (
                        <span>{lang.name}</span>
                      )}
                      error={!initialValueSelect && touched.item}
                    />
                  </SelectWrapper>
                </>
              )}

              <TitleIpnut>Purchase price</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter purchase price"
                  type="text"
                  name="purchase_price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purchase_price}
                  error={!!errors.purchase_price && touched.purchase_price}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Useful life</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter useful life"
                  type="text"
                  name="useful_life"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.useful_life}
                  error={!!errors.useful_life && touched.useful_life}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Purchase date</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="YYYY-MM-DD"
                  type="date"
                  name="purchase_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.purchase_date}
                  error={!!errors.purchase_date && touched.purchase_date}
                />
              </InputWrapperModalToken>

              {isLoading ? (
                <CustomLoader size={40} />
              ) : (
                <ModalButton disabled={isLoading}>
                  <button type="submit">Confirm</button>
                </ModalButton>
              )}
            </form>
          </ItemModal>
        )}
      </Formik>
      <CloseMod onClick={() => closeModal()}>x</CloseMod>
    </ModalContent>
  );
}

const SelectStyled = styled(Select)<{
  error?: boolean;
}>`
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  border-radius: 6px;
`;

const SelectWrapper = styled.div`
  height: 40px;
  color: #fff;
  border: none;
  background: #161d24;
  border-radius: 6px;
  margin: 9px 0 20px 0;
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  span {
    color: white;
  }
`;

const CloseMod = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #616d7a;
  font-size: 20px;
  :hover {
    cursor: pointer;
  }
`;

const TitleIpnut = styled.h6`
  margin-top: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 44px;
  background: #161d24;
  border-radius: 6px;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    :hover {
      cursor: pointer;
    }
  }
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  margin-top: 20px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;

    ${props => (props.disabled ? `opacity: 0.5; cursor: no-drop` : ``)};

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 414px;
  height: 538px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 20px;
  }

  > img {
    margin-top: 12px;
  }
`;
