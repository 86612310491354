import * as yup from "yup";

const schema = yup.object().shape({
    email: yup.string().email().required("Required field"),
    password: yup.string().min(8).required("Required field"),
    confirmPassword: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match'),
    username: yup.string().required("Required field").matches("^[a-zA-Z 0-9]+$", "Only [ a-z, A-Z, 0-9 ] allowed."),
    business_name: yup.string().required("Required field"),
    industry: yup.string(),

    creatdOn: yup.date().default(function () {
        return new Date()
    })
})

export default schema;