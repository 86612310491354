import styled from "styled-components";
import BGHeader from "../assets/img/BGHomepage.jpg";
import { ReactComponent as BoyGirl } from "../assets/img/boyGirl.svg";
import aboutCode from "../assets/img/aboutCode.png";
import aboutSettings from "../assets/img/aboutSettings.png";
import aboutCommynicating from "../assets/img/aboutCommunic.png";
import aboutTeam from "../assets/img/aboutTeam.png";
import aboutPhone from "../assets/img/aboutPhone.png";
import aboutService from "../assets/img/aboutSupport.png";
import designImg from "../assets/img/designImg.png";
import itemPhoto from "../assets/img/itemPhoto.png";
import formImg from "../assets/img/formImg.png";
import logo from "../assets/img/logo.svg";
import { useEffect, useState } from "react";
import { Modal } from "../components/Modal";
import { ModalSingUp } from "../components/common/Modals/ModalSingUp";
import { ModalSingIn } from "../components/common/Modals/ModalSingIn";
import { SnackBar } from "../components/SnackBar";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { useHistory } from "react-router-dom";

export const HomePage = () => {
  const [singUpVisible, setSingUpVisible] = useState<boolean>(false);
  const [singInVisible, setSingInVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const history = useHistory();

  const { data: isAuth } = useCheckAuth();

  useEffect(() => {
    isAuth && history.push("/home");
  }, [history, isAuth]);

  return (
    <HomePageWrapper>
      <Menu>
        <Container>
          <MenuWrapper>
            <div className="logo">
              <img src={logo} alt="" />
            </div>

            <div className="menu">
              <div>HOME</div>
              <div>ABOUT</div>
              <div>GALLERY</div>
              <div>CONTACT</div>

              <div
                onClick={() => {
                  setSingInVisible(true);
                }}
              >
                SING IN
              </div>
              <div
                onClick={() => {
                  setSingUpVisible(true);
                }}
              >
                SING UP
              </div>
            </div>

            <div className="mobMenu">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </MenuWrapper>
        </Container>
      </Menu>

      <HomePageHeader>
        <HPHeaderImg></HPHeaderImg>
        <HPHeaderText maxWidth={420}>
          <h2>Our creativity is your success</h2>
          <h4>
            We break all boundaries if they happen on our way of project’s
            development!
          </h4>
          <div className="butRead">READ MORE</div>
        </HPHeaderText>
      </HomePageHeader>

      <DigitalWrapper>
        <HPHeaderText maxWidth={560}>
          <h2>Digital Solutions provider for your brands</h2>
          <h4>
            Podcasting operational change management inside of workflows to
            establish a framework. Taking seamless key performance indicators
            offline to maximise the long tail. Keeping your eye on the ball
            while performing a deep dive on the start-up mentality to derive
            convergence on cross-platform integration.
          </h4>
          <div className="butRead">READ MORE</div>
        </HPHeaderText>
        <BoyGirlStyled />
      </DigitalWrapper>

      <AboutInfoWrapper>
        <Container>
          <WrapperAboutItems>
            <AboutItem>
              <img src={aboutCode} alt="" />
              <h5>Mobile Development</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>

            <AboutItem>
              <img src={aboutSettings} alt="" />
              <h5>Mobility Services</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>

            <AboutItem>
              <img src={aboutCommynicating} alt="" />
              <h5>Software Consulting</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>

            <AboutItem>
              <img src={aboutTeam} alt="" />
              <h5>Team Extension</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>

            <AboutItem>
              <img src={aboutPhone} alt="" />
              <h5>Application Services</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>

            <AboutItem>
              <img src={aboutService} alt="" />
              <h5>{"Maintenance & Support"}</h5>
              <span>
                Sample text. Click to select the text box. Click again or double
                click to start editing the text. Excepteur sint occaecat
                cupidatat non proident.
              </span>
            </AboutItem>
          </WrapperAboutItems>
        </Container>
      </AboutInfoWrapper>

      <DesignWrapperr>
        <h2>
          We design People Inspired Experiences that create positive change in
          people's lives.
        </h2>
        <div>READ MORE</div>
      </DesignWrapperr>

      <DesignNext>
        <Container>
          <DesNextWrapper>
            <div>
              <img src={designImg} alt="" />
            </div>
            <HPHeaderText maxWidth={560}>
              <h2>If you have any questions, please contact us</h2>
              <h4>
                Podcasting operational change management inside of workflows to
                establish a framework. Taking seamless key performance
                indicators offline to maximise the long tail. Keeping your eye
                on the ball while performing a deep dive on the start-up
                mentality to derive convergence on cross-platform integration.
              </h4>
              <div className="butRead">READ MORE</div>
            </HPHeaderText>
          </DesNextWrapper>
        </Container>
      </DesignNext>
      <InfoItemWrapper>
        <InfoTextWrap>
          <LeftInfoText>
            <h3>Why choose our studio for your projects?</h3>
            <p>
              Our mission is to Create Successful Partnerships. By doing so, we
              focus on ensuring a competitive edge and strive to make the
              building process a positive experience for our clients. <br />
              <br /> Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est
              laborum.
            </p>
          </LeftInfoText>
          <RightInfoText>
            <div>
              <h5>Services</h5>
              <ul>
                <li>Pre-Construction</li>
                <li>Conceptual Planning</li>
                <li>Design/Assist</li>
                <li>Design/Build</li>
                <li>General Contracting</li>
                <li>Construction Management</li>
              </ul>
            </div>
            <div>
              <h5 className="secondUl">Markets Served</h5>
              <ul>
                <li>Multi-family Residential</li>
                <li>Mixed Use</li>
                <li>Hospitality</li>
                <li>Senior Living</li>
                <li>Townhomes</li>
              </ul>
            </div>
          </RightInfoText>
        </InfoTextWrap>
        <InfoItemWrap>
          <InfoItem>
            <h2>16</h2>
            <span>Ranking</span>
          </InfoItem>
          <InfoItem>
            <h2>120</h2>
            <span>Clients</span>
          </InfoItem>
          <InfoItem>
            <h2>977</h2>
            <span>Cases</span>
          </InfoItem>
          <InfoItem>
            <h2>140</h2>
            <span>Awards</span>
          </InfoItem>
        </InfoItemWrap>
      </InfoItemWrapper>
      <TeamWrapper>
        <TeamTextWrap>
          <div>
            <h2>Meet The Team Our Professionals</h2>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Distinctio corporis delectus dolorem possimus, dolor rerum.
            </p>
          </div>
        </TeamTextWrap>
        <TeamItems>
          <ItemTeam>
            <img src={itemPhoto} alt="" />
            <h4>Jeffrey Brown</h4>
            <span>Creative Leader</span>
          </ItemTeam>
          <ItemTeam>
            <img src={itemPhoto} alt="" />
            <h4>Ann Richmond</h4>
            <span>Web Developer</span>
          </ItemTeam>
          <ItemTeam>
            <img src={itemPhoto} alt="" />
            <h4>Jennie Roberts</h4>
            <span>Sales Manager</span>
          </ItemTeam>
          <ItemTeam>
            <img src={itemPhoto} alt="" />
            <h4>Alex Greenfield</h4>
            <span>Programming Guru</span>
          </ItemTeam>
        </TeamItems>
      </TeamWrapper>
      <Form>
        <Container>
          <FormWrapper>
            <p>Email</p>
            <input type="text" placeholder="Enter a valid email address" />
            <p>Name</p>
            <input type="text" placeholder="Enter your name" />
            <p>Message</p>
            <input
              className="textar"
              type="textarea"
              placeholder="Enter your message"
            />
            <button>SUBMIT</button>
            <img src={formImg} alt="" />
          </FormWrapper>
        </Container>
      </Form>
      <Footer>
        <span>&#169;</span> 2022, Footer
      </Footer>

      {singUpVisible && (
        <Modal visible={singUpVisible} onClose={() => setSingUpVisible(false)}>
          <ModalSingUp closeModal={() => setSingUpVisible(false)} />
        </Modal>
      )}

      {singInVisible && (
        <Modal visible={singInVisible} onClose={() => setSingInVisible(false)}>
          <ModalSingIn closeModal={() => setSingInVisible(false)} />
        </Modal>
      )}

      {message && <SnackBar text={message} onClose={() => setMessage("")} />}
    </HomePageWrapper>
  );
};

const DesNextWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1280px) {
    justify-content: center;
  }

  div {
    img {
      width: 700px;
      margin-left: 65px;

      @media (max-width: 1280px) {
        width: 450px;
        margin-left: 0;
      }
      @media (max-width: 1170px) {
        width: 500px;
        margin-left: 40px;
      }
      @media (max-width: 1070px) {
        display: none;
      }
    }
  }
`;

const WrapperAboutItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 90px;

  @media (max-width: 1500px) {
    padding: 30px 30px;
  }
`;

const Container = styled.div`
  width: 1680px;
  margin: 0 auto;

  @media (max-width: 1740px) {
    width: 1540px;
  }

  @media (max-width: 1560px) {
    width: 1420px;
  }

  @media (max-width: 1380px) {
    width: 1180px;
  }

  @media (max-width: 1280px) {
    width: 92%;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;

  .logo {
    display: flex;
    align-items: center;
    > div {
      text-transform: uppecase;
      &:hover {
        cursor: pointer;
      }
      > a {
        color: white;
        margin-right: 10px;
      }
    }
  }

  div {
    img {
      height: 45px;
    }
  }

  .menu {
    display: flex;
    @media (max-width: 630px) {
      display: none;
    }
    div {
      margin-left: 25px;
      display: flex;
      align-items: center;

      &: hover {
        cursor: pointer;
        color: #000;
        text-decoration-line: underline;
      }
    }
  }

  .mobMenu {
    display: none;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 630px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align: items: center;
    }

    > div {
      height: 4px;
      width: 25px;
      background: #fff;
      margin: 2px 0;

      
    }
  }
`;

const Menu = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  background: #f6e4d0;
`;

const Footer = styled.div`
  height: 140px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;

  span {
    margin: 5px 4px 0 0;
    font-size: 20px;
  }
`;

const Form = styled.div`
  background: #f6e4d0;
  padding-top: 15px;
  margin-top: 70px;
`;

const FormWrapper = styled.div`
  max-width: 600px;
  margin: 50px auto 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > p {
    font-size: 20px;
    font-weight: bold;
    margin-right: auto;
    margin-top: 26px;
  }

  > input {
    width: 96%;
    height: 35px;
    padding-left: 16px;
    border: none;
    background: none;
    border-bottom: 2px solid #fff;
    letter-spacing: 0.6px;
    margin-top: 5px;
  }

  .textar {
    height: 80px;
  }

  button {
    background: #fff;
    margin-top: 22px;
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;

    &:hover {
      cursor: pointer;
      background: #f3f3f3;
    }
  }

  img {
    width: 80%;
  }
`;

const ItemTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 30px 0 30px;

  img {
    height: 200px;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 700;
    color: #000;
    margin: 16px 0;
  }

  span {
    font-size: 1rem;
    font-weight: 600;
    color: #3580f9;
  }
`;

const TeamWrapper = styled.div``;

const TeamTextWrap = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  > div {
    max-width: 400px;
    margin-top: 70px;

    > h2 {
      font-size: 3rem;
      font-weight: 700;
    }

    > p {
      font-size: 1rem;
      letter-spacing: 1px;
      line-height: 19px;
    }
  }

  > div:last-child {
    max-width: 600px;
    margin-left: 120px;
    @media (max-width: 1080px) {
      margin-left: 0;
    }
  }
`;

const TeamItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 1080px) {
    padding: 0 135px;
  }
  @media (max-width: 830px) {
    padding: 0 80px;
  }
  @media (max-width: 710px) {
    padding: 0 30px;
  }
  @media (max-width: 610px) {
    padding: 0;
  }
`;

const InfoItem = styled.div`
  background: #fff;
  color: #3580f9;
  width: 290px;
  height: 245px;
  text-align: center;
  margin: 10px;

  &:hover {
    -webkit-box-shadow: 0px 0px 55px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 55px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 55px 2px rgba(34, 60, 80, 0.2);
  }

  @media (max-width: 1080px) {
    height: 200px;
  }

  @media (max-width: 800px) {
    height: 180px;
  }

  h2 {
    font-size: 4.5rem;
    font-weight: 700;
    margin: 27% 0 25px 0;

    @media (max-width: 1080px) {
      margin: 23% 0 22px 0;
    }

    @media (max-width: 800px) {
      margin: 20% 0 20px 0;
    }
  }

  span {
    color: #000;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const InfoItemWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 1280px) {
    padding: 0 185px;
  }

  @media (max-width: 1010px) {
    padding: 0 100px;
  }

  @media (max-width: 930px) {
    padding: 0;
  }
`;

const LeftInfoText = styled.div`
  max-width: 637px;

  h3 {
    font-size: 3rem;
    font-weight: 700;
  }

  p {
    margin-top: 35px;
    line-height: 20px;
    letter-spacing: 1px;
  }
`;

const RightInfoText = styled.div`
  margin-left: 50px;

  @media (max-width: 720px) {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  h5 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .secondUl {
    margin-top: 30px;
    @media (max-width: 720px) {
      margin-top: 0;
    }
  }

  ul,
  li {
    list-style-type: disc;
    font-size: 1.125rem;
    line-height: 30px;
    @media (max-width: 720px) {
      list-style-type: none;
    }
  }
`;

const InfoTextWrap = styled.div`
  display: flex;
  padding: 80px;
  justify-content: space-around;
  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 560px) {
    padding: 80px 30px;
  }
`;

const InfoItemWrapper = styled.div`
  background: #3580f9;
  color: #fff;
  margin-top: 90px;
  padding-bottom: 60px;
`;

const DesignNext = styled.div`
  margin-top: 120px;
`;

const DesignWrapperr = styled.div`
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;

  h2 {
    font-size: 3rem;
    line-height: 1.6;
    font-weight: 700;
    @media (max-width: 700px) {
      font-size: 2.5rem;
      padding: 0 30px;
      line-height: 1.3;
    }
  }

  div {
    font-weight: 700;
    letter-spacing: 2px;
    margin-top: 30px;
    padding: 13px 0;
    background-color: #3580f9;
    color: #ffffff;
    border: 2px solid #3580f9;
    width: 230px;
    text-align: center;
    margin-left: calc(50% - 115px);

    &:hover {
      background: none;
      color: #3580f9;
      cursor: pointer;
    }
  }
`;

const AboutItem = styled.div`
  border-radius: 15px;
  padding: 40px 29px;
  background-color: #fff;
  max-width: 300px;
  margin: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 910px) {
    width: 230px;
  }

  @media (max-width: 700px) {
    width: 300px;
  }

  @media (max-width: 440px) {
    width: 250px;
  }

  &:hover {
    transform: scale(1.05);
  }

  img {
    height: 50px;
  }

  div {
    height: 30px;
    width: 30px;
    background: blue;
  }

  h5 {
    font-size: 1.25rem;
    margin-top: 20px;
  }

  span {
    font-size: 1rem;
    line-height: 1.8;
    margin-top: 20px;
  }
`;

const AboutInfoWrapper = styled.div`
  background: #f2f2f2;
`;

const BoyGirlStyled = styled(BoyGirl)`
  height: 600px;

  @media (max-width: 1070px) {
    display: none;
  }
`;

const DigitalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 680px) {
    padding: 0 30px;
  }
`;

const HPHeaderText = styled.div<{
  maxWidth: number;
}>`
  max-width: ${props => props.maxWidth}px;
  @media (max-width: 1280px) {
    margin-left: 40px;
  }

  @media (max-width: 1070px) {
    margin: 60px 0;
  }

  @media (max-width: 1070px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h2 {
    font-size: 3.75rem;
    font-weight: 700;
    @media (max-width: 700px) {
      font-size: 2.5rem;
      padding: 0 30px;
      line-height: 1.3;
    }
  }

  h4 {
    font-size: 1.25rem;
    margin-top: 30px;
    letter-spacing: 1px;
    line-height: 25px;
    @media (max-width: 680px) {
      font-size: 1rem;
    }
  }

  div {
    font-weight: 700;
    letter-spacing: 2px;
    margin: 30px auto 0 0;
    padding: 13px 0;
    background-color: #3580f9;
    color: #ffffff;
    border: 2px solid #3580f9;
    width: 190px;
    text-align: center;

    @media (max-width: 1070px) {
      margin: 30px 0 0 0;
    }

    &:hover {
      background: none;
      color: #3580f9;
      cursor: pointer;
    }
  }
`;

const HPHeaderImg = styled.div`
  width: 56%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1440px) {
    width: 53%;
  }

  @media (max-width: 1280px) {
    width: 54%;
  }

  @media (max-width: 1120px) {
    width: 57%;
  }

  @media (max-width: 1070px) {
    display: none;
  }

  img {
    transform: scaleX(-1);
    margin-bottom: -190px;
  }
`;

const HomePageHeader = styled.div`
  background-image: url(${BGHeader});
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: calc(100vh - 60px);

  @media (max-width: 1070px) {
    justify-content: center;
    background: #f9ede1;
  }
`;

const HomePageWrapper = styled.div`
  width: 100%;
`;
