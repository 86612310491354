import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";

type MutateDataType = {
  email: string;
  password: string;
};

function usePostAutoriz(onCloseModal: () => void) {
  const context = useContext(Context);
  const history = useHistory();
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: MutateDataType) =>
      axios.post<{ access: string; refresh: string }>(
        `${apiRouteTest}/api/token/`,
        data,
      ),
    {
      onSuccess: res => {
        localStorage.setItem("authToken", res.data.access);
        queryClient.invalidateQueries("checkAuth");
        onCloseModal();
        history.push("/home");
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostAutoriz };
