import { Formik } from "formik";
import styled from "styled-components";
import validationModalAddUserAccruedI from "./validationModalAddUserAccruedI";
import { usePostAccruedInterest } from "../../../../hooks/usePostAccruedInterest";
import { CustomLoader } from "../../../Loader";
//import { useCheckAuth } from "../../../../hooks/useCheckAuth";
//import { useDecodeJwt } from "../../../../hooks/useDecodeJwt";

type PropsModal = {
  closeModal: () => void;
};

export function ModalAddUserAccruedI({ closeModal }: PropsModal) {
  const { mutate: addUser, isLoading } = usePostAccruedInterest(closeModal);
  //const { data: authToken } = useCheckAuth();
  //const { data: userData } = useDecodeJwt(authToken || "");

  return (
    <ModalContent>
      <h3>Add new item</h3>
      <Formik
        initialValues={{
          round: "",
          principal: "",
          date: "",
          interest: "",
          investor: "",
        }}
        validationSchema={validationModalAddUserAccruedI}
        onSubmit={(values, { setSubmitting }) => {
          addUser({
            date: values.date,
            interest: +values.interest,
            investor: values.investor,
            principal: +values.principal,
            round: values.round,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Investor</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter interest"
                  type="text"
                  name="investor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.investor}
                  error={!!errors.investor && touched.investor}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Round</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter round"
                  type="text"
                  name="round"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.round}
                  error={!!errors.round && touched.round}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Principal</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter principal"
                  type="text"
                  name="principal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.principal}
                  error={!!errors.principal && touched.principal}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Date</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="YYYY-MM-DD"
                  type="date"
                  name="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.date}
                  error={!!errors.date && touched.date}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Interest</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter interest"
                  type="text"
                  name="interest"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.interest}
                  error={!!errors.interest && touched.interest}
                />
              </InputWrapperModalToken>
              {isLoading ? (
                <CustomLoader size={40} />
              ) : (
                <ModalButton disabled={isLoading}>
                  <button type="submit">Confirm</button>
                </ModalButton>
              )}
            </form>
          </ItemModal>
        )}
      </Formik>
      <CloseMod onClick={() => closeModal()}>x</CloseMod>
    </ModalContent>
  );
}

const CloseMod = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #616d7a;
  font-size: 20px;
  :hover {
    cursor: pointer;
  }
`;

const TitleIpnut = styled.h6`
  margin-top: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 40px;
  background: #161d24;
  border-radius: 6px;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    :hover {
      cursor: pointer;
    }
  }
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  margin-top: 20px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;

    ${props => (props.disabled ? `opacity: 0.5; cursor: no-drop` : ``)};

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 425px;
  height: 545px;
  position: relative;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 20px;
  }

  > img {
    margin-top: 12px;
  }
`;
