import styled from "styled-components";
import logo from "../assets/img/logo.svg";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { NavLink } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "../components/Modal";
import { ModalGetKeys } from "../components/common/Modals/ModalGetKeys";
import { queryClient } from "../utils/api";
import { useDecodeJwt } from "../hooks/useDecodeJwt";
import { usePostMappingQBO } from "../hooks/usePostMappingQBO";
import { usePostGetTokens } from "../hooks/usePostGetTokens";
import { CustomLoader } from "../components/Loader";

export const MainPage = () => {
  const [openGetKeys, setOpenGetKeys] = useState<boolean>(false);
  const { data: authToken } = useCheckAuth();
  const { data: userData } = useDecodeJwt(authToken || "");
  const { mutate: connect } = usePostGetTokens();
  const { mutate: mappingQBO, isLoading } = usePostMappingQBO();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const logOut = () => {
    localStorage.clear();
    queryClient.invalidateQueries("checkAuth");
  };

  const client_secret = localStorage.getItem("client_secret") || "";
  const client_id = localStorage.getItem("client_id") || "";

  useEffect(() => {
    const state = params.get("state");
    !localStorage.getItem("state") &&
      state &&
      localStorage.setItem("state", state);

    const code = params.get("code");
    !localStorage.getItem("code") && code && localStorage.setItem("code", code);

    const realm_id = params.get("realmId");
    !localStorage.getItem("realm_id") &&
      realm_id &&
      localStorage.setItem("realm_id", realm_id);

    state &&
      code &&
      realm_id &&
      connect({ state, code, realm_id, client_id, client_secret });
  }, [client_id, client_secret, connect, params]);

  const url = localStorage.getItem("oauth_url");
  const access_token = localStorage.getItem("access_token") || "";
  const token_id = localStorage.getItem("id_token");

  const handleLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    !!token_id && e.preventDefault();
  };

  const handleTableClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    !token_id && e.preventDefault();
  };

  return (
    <PageWrapper>
      <Menu>
        <Container>
          <MenuWrapper>
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="menu">
              <div onClick={() => logOut()}>LOG OUT</div>
              <div>{`QBO: ${!!access_token ? "Connect" : "No Connect"}`}</div>
            </div>

            <div className="mobMenu">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </MenuWrapper>
        </Container>
      </Menu>

      <HomePageHeader>
        <h2>Welcome !</h2>
        <TableWrapper>
          <NavLink to={"/home/accured"} onClick={handleTableClick}>
            <LinkTableItem dis={!token_id}>ACCURED</LinkTableItem>
          </NavLink>
          <NavLink to={"/home/deprication"} onClick={handleTableClick}>
            <LinkTableItem dis={!token_id}>DEPRECIATION</LinkTableItem>
          </NavLink>
          <NavLink to={"/home/prepaid"} onClick={handleTableClick}>
            <LinkTableItem dis={!token_id}>PREPAID</LinkTableItem>
          </NavLink>
          <NavLink to={"/home/deferred"} onClick={handleTableClick}>
            <LinkTableItem dis={!token_id}>DEFERRED</LinkTableItem>
          </NavLink>
        </TableWrapper>
        <ButtonWrap>
          <NavLink to={""} />
          {!!url ? (
            <ConnectQBO
              target={!!token_id ? "" : "_blank"}
              href={!!token_id ? "" : url}
              dis={!!token_id}
              onClick={handleLinkClick}
            >
              Connect to QBO
            </ConnectQBO>
          ) : (
            <GetKeys onClick={() => setOpenGetKeys(!token_id)} dis={!!token_id}>
              Get key's QBO
            </GetKeys>
          )}
          {isLoading ? (
            <WrapBut>
              <CustomLoader height="30px" size={45} />
            </WrapBut>
          ) : (
            <MappingQBO
              dis={!token_id}
              onClick={() =>
                mappingQBO({
                  access_token,
                })
              }
            >
              Mapping QBO
            </MappingQBO>
          )}
        </ButtonWrap>
      </HomePageHeader>

      <Footer>
        <span>&#169;</span> 2022, Footer
      </Footer>
      {openGetKeys && userData && (
        <Modal onClose={() => setOpenGetKeys(false)} visible={openGetKeys}>
          <ModalGetKeys
            userId={userData.user_id}
            closeModal={() => setOpenGetKeys(false)}
          />
        </Modal>
      )}
    </PageWrapper>
  );
};

const WrapBut = styled.div`
  width: 180px;
`;

const MappingQBO = styled.div<{
  dis?: boolean;
}>`
  background: #fff;
  border: 1px solid gray;
  min-width: 150px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  ${props => (props.dis ? "opacity: 0.5;" : "")}
  :hover {
    ${props =>
      props.dis
        ? "cursor: no-drop;"
        : "cursor: pointer; box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -webkit-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -moz-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52);"}
  }
`;

const ButtonWrap = styled.div`
  display: flex;
`;

const ConnectQBO = styled.a<{
  dis: boolean;
}>`
  ${props => (props.dis ? `opacity: 0.5;` : "")}
  background: #43ba21;
  color: #fff;

  min-width: 150px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  :hover {
    ${props =>
      props.dis
        ? `cursor: not-allowed;`
        : "cursor: pointer; box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -webkit-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -moz-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52);"}
  }
`;

const GetKeys = styled.div<{
  dis: boolean;
}>`
  ${props => (props.dis ? "opacity: 0.5;" : "")}
  background: blue;
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 15px;
  :hover {
    ${props =>
      props.dis
        ? "cursor: no-drop;"
        : "cursor: pointer; box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -webkit-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -moz-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52);"}
  }
`;

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 50px 0;
  > a {
    color: black;
  }
`;

const LinkTableItem = styled.div<{
  dis: boolean;
}>`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid gray;
  border-radius: 8px;
  width: 200px;
  margin: 0 40px;
  ${props => (props.dis ? "opacity: 0.5;" : "")}
  :hover {
    ${props =>
      props.dis
        ? "cursor: no-drop;"
        : "cursor: pointer; box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -webkit-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52); -moz-box-shadow: 1px -1px 32px 3px rgba(133, 119, 119, 0.52);"}
  }
`;

const Container = styled.div`
  width: 1680px;
  margin: 0 auto;

  @media (max-width: 1740px) {
    width: 1540px;
  }

  @media (max-width: 1560px) {
    width: 1420px;
  }

  @media (max-width: 1380px) {
    width: 1180px;
  }

  @media (max-width: 1280px) {
    width: 92%;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;

  .logo {
    display: flex;
    align-items: center;
    > div {
      text-transform: uppecase;
      &:hover {
        cursor: pointer;
      }
      > a {
        color: white;
        margin-right: 10px;
      }
    }
  }

  div {
    img {
      height: 45px;
    }
  }

  .menu {
    display: flex;
    @media (max-width: 630px) {
      display: none;
    }
    div {
      margin-left: 25px;
      display: flex;
      align-items: center;

      &: hover {
        cursor: pointer;
        color: #000;
        text-decoration-line: underline;
      }
    }
  }

  .mobMenu {
    display: none;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 630px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align: items: center;
    }

    > div {
      height: 4px;
      width: 25px;
      background: #fff;
      margin: 2px 0;

      
    }
  }
`;

const Menu = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  background: #f6e4d0;
`;

const Footer = styled.div`
  height: 140px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 17px;

  span {
    margin: 5px 4px 0 0;
    font-size: 20px;
  }
`;

const HomePageHeader = styled.div`
  background: #f9ede1;
  height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 40px;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
`;
