import * as yup from "yup";

const schema = yup.object().shape({
    invoice_date: yup.string().required("Required field"),
    periods: yup.number().required("Required field"),
    invoice_total: yup.number().required("Required field"),
    customer: yup.string().required("Required field"),

    creatdOn: yup.date().default(function () {
        return new Date()
    })
})

export default schema;