import * as yup from "yup";

const schema = yup.object().shape({
  item: yup.string().required("Required field"),
  category: yup.number().required("Required field"),
  purchase_price: yup.number().required("Required field"),
  useful_life: yup.number().required("Required field"),
  purchase_date: yup.date().required("Required field"),

  creatdOn: yup.date().default(function () {
    return new Date();
  }),
});

export default schema;
