import { useQuery } from "react-query";

function useCheckAuth() {
  const { data, isLoading } = useQuery(["checkAuth"], () =>
    localStorage.getItem("authToken"),
  );

  return { data, isLoading };
}

export { useCheckAuth };
