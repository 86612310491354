import * as yup from "yup";

const schema = yup.object().shape({
    round: yup.string().required("Required field"),
    principal: yup.number().required("Required field"),
    date: yup.string().required("Required field"),
    investor: yup.string().required("Required field"),
    interest: yup.number().required("Required field"),

    creatdOn: yup.date().default(function () {
        return new Date()
    })
})

export default schema;