import styled from "styled-components";
import { useTable } from "react-table";
import React, { useState } from "react";
import { Modal } from "../../../Modal";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "../../../Loader";
import { useGetDeferredRevenue } from "../../../../hooks/useGetDeferredRevenue";
import { ModalAddUserDeferredRevenue } from "../../Modals/ModalAddUserDeferredRevenue";
import { usePostDeferredRevenuePush } from "../../../../hooks/usePostDeferredRevenuePush";
import { ModalUpdateDeferredRevenue } from "../../Modals/ModalUpdateDeferredRevenue";
import { ModalAcceptDelField } from "../../Modals/ModalAcceptDelField";
import trash from "../../../../assets/img/trash.png";
import pushed from "../../../../assets/img/correct.png";
import noPushed from "../../../../assets/img/error.png";

enum Headers {
  CUSTOMER = "Customer",
  INOVICE_DATE = "Invoice Date",
  PERIOD = "Periods (Months)",
  INOVICE_TOTAL = "Invoice Total",
  MONTHLY_RECORD = "Monthly Recognition",
  PUSHED = "Pushed",
  EDIT = "Actions",
}

type DeferredRevenueType = {
  client_id: number;
  invoice_date: string;
  periods: number;
  invoice_total: number;
  recognition: number;
  calculated_months: number;
  created_at: string;
  id: number;
  monthly_dep: number;
  updated_at: string;
  customer: string;
  pushed: boolean;
};

type PropsTableTimeType = {
  setupTime: number;
  now: number;
};

type UpdateDataType = {
  invoice_date: string;
  periods: number;
  invoice_total: number;
  client_id: number;
  customer: string;
  id: number;
};

export const DeferredRevenueTable = ({
  setupTime,
  now,
}: PropsTableTimeType) => {
  const dataDeferredRevenue = useGetDeferredRevenue();
  const { mutate: push, isLoading } = usePostDeferredRevenuePush();
  const access_token = localStorage.getItem("access_token") || "";

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [numberDel, setNumberDel] = useState<number>(0);
  const funcDelete = (id: number) => {
    setNumberDel(id);
    setOpenDelete(true);
  };

  if (setupTime == null) {
    localStorage.setItem("setupTime", String(now));
  } else {
    if (now - Number(setupTime) > 60 * 60 * 1000) {
      localStorage.clear();
    }
  }

  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<UpdateDataType>({
    client_id: 0,
    customer: "",
    invoice_date: "",
    id: 0,
    invoice_total: 0,
    periods: 0,
  });

  const openUpdate = ({
    client_id,
    id,
    periods,
    customer,
    invoice_date,
    invoice_total,
  }: UpdateDataType) => {
    setActiveTab({
      client_id,
      id,
      periods,
      customer,
      invoice_date,
      invoice_total,
    });
    setOpenModalUpdate(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: Headers.CUSTOMER,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText className="name">{originalRow.customer}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.INOVICE_DATE,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText className="date">{originalRow.invoice_date}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.PERIOD,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText className="period">{originalRow.periods} </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.INOVICE_TOTAL,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText className="total">
              $ {originalRow.invoice_total}
            </TableText>
          </PlaceCell>
        ),
      },

      {
        Header: Headers.MONTHLY_RECORD,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText>{originalRow.monthly_dep}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.PUSHED,
        accessor: (originalRow: DeferredRevenueType) => (
          <PlaceCell>
            <TableText>
              <AcceptDiv>
                {originalRow.pushed ? (
                  <img src={pushed} alt="" />
                ) : (
                  <img src={noPushed} alt="" />
                )}
              </AcceptDiv>
            </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.EDIT,
        accessor: (originalRow: DeferredRevenueType) => (
          <Actions>
            <Edit
              disable={originalRow.pushed}
              onClick={() =>
                originalRow.pushed
                  ? ""
                  : openUpdate({
                      client_id: originalRow.client_id,
                      customer: originalRow.customer,
                      id: originalRow.id,
                      invoice_date: originalRow.invoice_date,
                      invoice_total: originalRow.invoice_total,
                      periods: originalRow.periods,
                    })
              }
            >
              Edit
            </Edit>
            <Delete disable={originalRow.pushed}>
              <img
                src={trash}
                onClick={() =>
                  originalRow.pushed ? "" : funcDelete(originalRow.id)
                }
                alt=""
              />
            </Delete>
          </Actions>
        ),
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      data: dataDeferredRevenue.data?.data || [],
      columns,
    });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const history = useHistory();

  return (
    <>
      <Back onClick={() => history.push("/home")}>home</Back>
      {dataDeferredRevenue.isLoading ? (
        <CustomLoader height="40px" />
      ) : (
        <>
          <NameTable>Deferred Revenue Table</NameTable>
          <TableWrapper>
            <Table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
          <ButtonsWrap>
            <AddUser onClick={() => setOpenModal(true)}>
              Add new article
            </AddUser>

            {isLoading ? (
              <WrapBut>
                <CustomLoader height="30px" size={45} />
              </WrapBut>
            ) : (
              <AddUser onClick={() => push({ access_token })}>
                Push to QBO
              </AddUser>
            )}
          </ButtonsWrap>
        </>
      )}

      {openModal && (
        <Modal onClose={() => setOpenModal(false)} visible={openModal}>
          <ModalAddUserDeferredRevenue onClose={() => setOpenModal(false)} />
        </Modal>
      )}

      {openDelete && (
        <Modal onClose={() => setOpenDelete(false)} visible={openDelete}>
          <ModalAcceptDelField
            onClose={() => setOpenDelete(false)}
            type={"deferred-revenue"}
            id={numberDel}
          />
        </Modal>
      )}

      {openModalUpdate && (
        <Modal
          onClose={() => setOpenModalUpdate(false)}
          visible={openModalUpdate}
        >
          <ModalUpdateDeferredRevenue
            client_id={activeTab.client_id}
            id={activeTab.id}
            onClose={() => setOpenModalUpdate(false)}
            periods={activeTab.periods}
            customer={activeTab.customer}
            invoice_date={activeTab.invoice_date}
            invoice_total={activeTab.invoice_total}
          />
        </Modal>
      )}
    </>
  );
};

const AcceptDiv = styled.div`
  img {
    width: 20px;
    height: 20px;
    margin-right: -50px;
  }
`;

const Delete = styled.div<{
  disable: boolean;
}>`
  ${props => (props.disable ? `opacity: 0.7;` : ``)}
  img {
    width: 18px;
    height: 22px;
    margin-left: 8px;
    ${props => (props.disable ? `cursor: no-drop;` : `cursor: pointer;`)}
  }
`;

const Edit = styled.div<{
  disable: boolean;
}>`
  border: 1px solid gray;
  border-radius: 20px;
  padding: 3px;
  width: 50px;
  ${props => (props.disable ? `opacity: 0.7;` : `1;`)}

  :hover {
    ${props =>
      props.disable
        ? `cursor: no-drop;`
        : `cursor: pointer;
    border: 1px solid gray; color: white; background: gray;`}
  }
`;

const Actions = styled.div`
  color: #8e8e8e;
  min-width: 75px;
  margin-left: -15px;
  text-align: center;
  display: flex;
}
`;

const WrapBut = styled.div`
  width: 200px;
  margin-top: 45px;
  margin-left: 60px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  margin: 0 auto;
`;

const NameTable = styled.div`
  text-align: center;
  font-size: 27px;
`;

const Back = styled.div`
  margin: 20px 0 0 30px;
  width: 100px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`;

const AddUser = styled.div`
  padding: 20px 40px;
  background: green;
  color: white;
  width: 200px;
  text-align: right;
  border-radius: 3px;
  margin: 40px 30px;

  &:hover {
    cursor: pointer;
    background: #2bd12b;
  }
`;

const TableWrapper = styled.div`
  max-width: 1080px;
  width: 90%;
  margin: 30px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;

  thead {
    > tr {
      color: white;
      background: #36304a;
      height: 70px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      justify-content: space-around;
      display: flex;
      align-items: center;
    }
  }

  tbody {
    tr {
      background: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px 0;
    }

    tr:nth-child(2n) {
      background: #f5f5f5;
    }

    tr:last-child {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

const TableText = styled.span`
  font-size: 14px;
  line-height: 17px;
  span {
    color: #000;
  }
`;

const PlaceCell = styled.div`
  color: #8e8e8e;
  text-align: center;
  min-width: 100px;

  > .name {
    margin-left: -40px;
  }

  > .date {
    margin-left: -70px;
  }

  > .period {
    margin-left: -70px;
  }

  > .total {
    margin-left: -50px;
  }
`;
