import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";

type DecodeType = {
  exp: number;
  jti: string;
  token_type: string;
  user_id: number;
};

function useDecodeJwt(authToken: string) {
  const { data, isLoading } = useQuery(
    ["decodeJwt", authToken],
    (): DecodeType => jwt_decode(authToken),
    { enabled: !!authToken },
  );

  return { data, isLoading };
}

export { useDecodeJwt };
