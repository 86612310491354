import styled from "styled-components";
import { useTable } from "react-table";
import React, { useState } from "react";
import { Modal } from "../../../Modal";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "../../../Loader";
import { useGetDepreciation } from "../../../../hooks/useGetDepreciation";
import { ModalAddUserDepreciation } from "../../Modals/ModalAddUserDepreciation";
import { useDeprecationPush } from "../../../../hooks/useDeprecationPush";
import { useGetItems } from "../../../../hooks/useGetItemsDeprecation";
import { useGetCategory } from "../../../../hooks/useGetCategoryDeprecation";
import { ModalUpdateDepreciation } from "../../Modals/ModalUpdateDepreciation";
import trash from "../../../../assets/img/trash.png";
import pushed from "../../../../assets/img/correct.png";
import noPushed from "../../../../assets/img/error.png";
import { ModalAcceptDelField } from "../../Modals/ModalAcceptDelField";

enum Headers {
  ITEM = "Item",
  CATEGORY = "Category",
  PRICE = "Price",
  USEFUL_LIFE = "Useful Life",
  PURCHASE_DATE = "Purchase Date",
  MONTHLY_DEP = "Monthly Dep",
  PUSHED = "Pushed",
  EDIT = "Actions",
}

export type TableDepreciationType = {
  item: string;
  category: string;
  purchase_price: number;
  useful_life: number;
  purchase_date: string;
  client_id: number;
  monthly_dep: number;
  pushed: boolean;
  id: number;
};

type PropsTableTimeType = {
  setupTime: number;
  now: number;
};

type UpdateDataType = {
  item: string;
  category: string;
  purchase_price: number;
  useful_life: number;
  purchase_date: string;
  id: number;
  client_id: number;
};

export const DepreciationAmortizationTable = ({
  setupTime,
  now,
}: PropsTableTimeType) => {
  const { data: dataDepreciation, isLoading } = useGetDepreciation();

  const { data: dataItems, isLoading: loadingItems } = useGetItems();
  const { data: dataCategory, isLoading: loadingCategory } = useGetCategory();

  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false);

  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [numberDel, setNumberDel] = useState<number>(0);
  const funcDelete = (id: number) => {
    setNumberDel(id);
    setOpenDelete(true);
  };

  if (setupTime == null) {
    localStorage.setItem("setupTime", String(now));
  } else {
    if (now - Number(setupTime) > 60 * 60 * 1000) {
      localStorage.clear();
    }
  }

  const [openModal, setOpenModal] = useState<boolean>(false);

  const history = useHistory();

  const access_token = localStorage.getItem("access_token") || "";

  const { mutate: push, isLoading: loadPush } = useDeprecationPush();

  const [activeTab, setActiveTab] = useState<UpdateDataType>({
    item: "",
    category: "",
    purchase_price: 0,
    useful_life: 0,
    purchase_date: "",
    client_id: 0,
    id: 0,
  });

  const openUpdate = ({
    item,
    category,
    purchase_price,
    useful_life,
    purchase_date,
    client_id,
    id,
  }: UpdateDataType) => {
    setActiveTab({
      item,
      category,
      purchase_price,
      useful_life,
      purchase_date,
      client_id,
      id,
    });
    setOpenModalUpdate(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: Headers.ITEM,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell className="item">
            <TableText>
              {originalRow.item}
              {/* {dataItems?.data
                .filter(i => i.id === +originalRow.item)
                .map(it => it.name)[0]
                .split("")
                .slice(0, 20)
                .join("")} */}
            </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.CATEGORY,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell className="category">
            <TableText>
              {dataCategory?.data
                .filter(i => i.id === +originalRow.category)
                .map(it => it.name)[0]
                .split("")
                .slice(0, 17)
                .join("")}
            </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.PRICE,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell className="price">
            <TableText>$ {originalRow.purchase_price}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.USEFUL_LIFE,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell className="life">
            <TableText>{originalRow.useful_life} </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.PURCHASE_DATE,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell className="date">
            <TableText>{originalRow.purchase_date}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.MONTHLY_DEP,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell>
            <TableText>{originalRow.monthly_dep}</TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.PUSHED,
        accessor: (originalRow: TableDepreciationType) => (
          <PlaceCell>
            <TableText>
              <AcceptDiv>
                {originalRow.pushed ? (
                  <img src={pushed} alt="" />
                ) : (
                  <img src={noPushed} alt="" />
                )}
              </AcceptDiv>
            </TableText>
          </PlaceCell>
        ),
      },
      {
        Header: Headers.EDIT,
        accessor: (originalRow: TableDepreciationType) => (
          <Actions>
            <Edit
              disable={originalRow.pushed}
              onClick={() =>
                originalRow.pushed
                  ? ""
                  : openUpdate({
                      category: originalRow.category,
                      item: originalRow.item,
                      purchase_price: originalRow.purchase_price,
                      useful_life: originalRow.useful_life,
                      purchase_date: originalRow.purchase_date,
                      client_id: originalRow.client_id,
                      id: originalRow.id,
                    })
              }
            >
              Edit
            </Edit>
            <Delete disable={originalRow.pushed}>
              <img
                src={trash}
                onClick={() =>
                  originalRow.pushed ? "" : funcDelete(originalRow.id)
                }
                alt=""
              />
            </Delete>
          </Actions>
        ),
      },
    ],
    [dataCategory?.data, dataItems?.data],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      data: dataDepreciation?.data || [],
      columns,
    });

  return (
    <>
      <Back onClick={() => history.push("/home")}>home</Back>
      {isLoading && loadingItems && loadingCategory ? (
        <CustomLoader height="40px" />
      ) : (
        <>
          <NameTable>Depreciation Amortization Table</NameTable>
          <TableWrapper>
            <Table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      return (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableWrapper>
        </>
      )}
      <ButtonsWrap>
        <AddUser onClick={() => setOpenModal(true)}>Add new article</AddUser>

        {loadPush ? (
          <WrapBut>
            <CustomLoader height="30px" size={45} />
          </WrapBut>
        ) : (
          <AddUser onClick={() => push({ access_token })}>Push to QBO</AddUser>
        )}
      </ButtonsWrap>

      {openModal && (
        <Modal onClose={() => setOpenModal(false)} visible={openModal}>
          <ModalAddUserDepreciation closeModal={() => setOpenModal(false)} />
        </Modal>
      )}

      {openDelete && (
        <Modal onClose={() => setOpenDelete(false)} visible={openDelete}>
          <ModalAcceptDelField
            onClose={() => setOpenDelete(false)}
            type={"depreciation-amortization"}
            id={numberDel}
          />
        </Modal>
      )}

      {openModalUpdate && (
        <Modal
          onClose={() => setOpenModalUpdate(false)}
          visible={openModalUpdate}
        >
          <ModalUpdateDepreciation
            closeModal={() => setOpenModalUpdate(false)}
            purchase_date={activeTab.purchase_date}
            item={+activeTab.item}
            category={+activeTab.category}
            purchase_price={activeTab.purchase_price}
            useful_life={activeTab.useful_life}
            client_id={activeTab.client_id}
            id={activeTab.id}
          />
        </Modal>
      )}
    </>
  );
};

const AcceptDiv = styled.div`
  img {
    width: 20px;
    height: 20px;
  }
`;

const Delete = styled.div<{
  disable: boolean;
}>`
  ${props => (props.disable ? `opacity: 0.7;` : ``)}
  img {
    width: 18px;
    height: 22px;
    margin-left: 8px;
    ${props => (props.disable ? `cursor: no-drop;` : `cursor: pointer;`)}
  }
`;

const Edit = styled.div<{
  disable: boolean;
}>`
  border: 1px solid gray;
  border-radius: 20px;
  padding: 3px;
  width: 50px;
  ${props => (props.disable ? `opacity: 0.7;` : `1;`)}

  :hover {
    ${props =>
      props.disable
        ? `cursor: no-drop;`
        : `cursor: pointer;
    border: 1px solid gray; color: white; background: gray;`}
  }
`;

const Actions = styled.div`
  color: #8e8e8e;
  min-width: 75px;
  margin-left: -15px;
  text-align: center;
  display: flex;
  margin-right: 15px;
}
`;

const WrapBut = styled.div`
  width: 200px;
  margin-top: 45px;
  margin-left: 60px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  margin: 0 auto;
`;

const NameTable = styled.div`
  text-align: center;
  font-size: 27px;
`;

const Back = styled.div`
  margin: 20px 0 0 30px;
  width: 100px;
  height: 32px;

  &:hover {
    cursor: pointer;
  }
`;

const AddUser = styled.div`
  padding: 20px 40px;
  background: green;
  color: white;
  width: 200px;
  text-align: right;
  border-radius: 3px;
  margin: 40px 30px;

  &:hover {
    cursor: pointer;
    background: #2bd12b;
  }
`;

const TableWrapper = styled.div`
  max-width: 1080px;
  width: 90%;
  margin: 30px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;

  thead {
    > tr {
      color: white;
      background: #36304a;
      height: 70px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      justify-content: space-between;
      padding: 0 40px;
      display: flex;
      align-items: center;
    }
  }

  tbody {
    tr {
      background: #fff;
      display: flex;
      align-items: center;
      padding: 10px 0;
      justify-content: space-between;
    }

    tr:nth-child(2n) {
      background: #f5f5f5;
    }

    tr:last-child {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

const TableText = styled.span`
  font-size: 14px;
  line-height: 17px;

  span {
    color: #000;
  }
`;

const PlaceCell = styled.div`
  color: #8e8e8e;
  text-align: center;
  min-width: 110px;

  &.category {
    margin-left: -20px;
  }
  &.price {
    margin-left: -50px;
  }
  &.life {
    margin-left: -25px;
  }
  &.date {
    margin-right: 55px;
  }
`;
