import { Formik } from "formik";
import { useEffect } from "react";
import styled from "styled-components";
import { usePostAutoriz } from "../../../../hooks/usePostAutoriz";
import { CustomLoader } from "../../../Loader";
import validationSingIn from "./validationSingIn";

type PropsType = {
  closeModal: () => void;
};

export function ModalSingIn({ closeModal }: PropsType) {
  const {
    mutate: autoriz,
    data: regData,
    isLoading,
  } = usePostAutoriz(closeModal);

  useEffect(() => {
    regData?.data && localStorage.setItem("authToken", regData.data.access);
  }, [regData?.data]);

  return (
    <ModalContent>
      <h3>Sign In</h3>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSingIn}
        onSubmit={(values, { setSubmitting }) => {
          autoriz(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Email</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter email"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!errors.email && touched.email}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Password</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter password, min 8 symbols"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={!!errors.password && touched.password}
                />
              </InputWrapperModalToken>

              <ModalButton>
                {isLoading ? (
                  <CustomLoader size={40} height={"40px"} />
                ) : (
                  <button type="submit">Login</button>
                )}
              </ModalButton>
            </form>
          </ItemModal>
        )}
      </Formik>
    </ModalContent>
  );
}

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 40px;
  background: #fff;
  border-radius: 3px;
  color: #000;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#d8d8d8")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }
`;

///

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: center;

  button {
    color: #fff;
    background: #0073ea;
    border: 2px solid #0073ea;
    border-radius: 13px;
    padding: 10px 33px;
    letter-spacing: 1px;

    &:hover {
      background: none;
      color: #0073ea;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  width: 345px;
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  height: 335px;

  background: #fff;
  border-radius: 12px;
  color: #000;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
