import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

type AccruedInterestType = {
  item: string;
  category: number;
  purchase_price: number;
  useful_life: number;
  purchase_date: string;
  client_id: number;
};

function usePostDepreciation(onClose: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: AccruedInterestType) =>
      axios.post(`${apiRouteTest}/depreciation-amortization/`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDepreciation");
        onClose();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostDepreciation };
