import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";

type AccruedInterestType = {
  item: number;
  category: number;
  purchase_price: number;
  useful_life: number;
  purchase_date: string;
  client_id: number;
  id: number;
};

function usePutDepreciation(onClose: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: AccruedInterestType) =>
      axios.put(
        `${apiRouteTest}/depreciation-amortization-edit/${data.id}/`,
        data,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDepreciation");
        onClose();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePutDepreciation };
