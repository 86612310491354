import { Formik } from "formik";
import styled from "styled-components";
import { usePostConnectToQBO } from "../../../../hooks/usePostConnectToQBO";
import { CustomLoader } from "../../../Loader";
import validationModalGetKeys from "./validationModalGetKeys";

type PropsType = {
  closeModal: () => void;
  userId: number;
};

const oauth_url = localStorage.getItem("oauth_url");

export function ModalGetKeys({ closeModal, userId }: PropsType) {
  const { mutate: connect, isLoading } = usePostConnectToQBO(closeModal);

  return (
    <ModalContent>
      <h3>Connect to QBO</h3>
      <Formik
        initialValues={{
          client_secret: "",
          client_id: "",
        }}
        validationSchema={validationModalGetKeys}
        onSubmit={(values, { setSubmitting }) => {
          connect({
            client_id: values.client_id,
            client_secret: values.client_secret,
            user_id: userId,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Clint ID</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter client ID"
                  type="text"
                  name="client_id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.client_id}
                  error={!!errors.client_id && touched.client_id}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Client Secret</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter client secret"
                  type="text"
                  name="client_secret"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.client_secret}
                  error={!!errors.client_secret && touched.client_secret}
                />
              </InputWrapperModalToken>

              <ModalButton>
                {isLoading ? (
                  <CustomLoader size={40} height={"40px"} />
                ) : (
                  <ButWrap>
                    <button type="submit">Connecting</button>
                    <a
                      href="https://developer.intuit.com/app/developer/dashboard"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Go to QBO
                    </a>
                  </ButWrap>
                )}
              </ModalButton>
            </form>
          </ItemModal>
        )}
      </Formik>
    </ModalContent>
  );
}

const ButWrap = styled.div`
  button {
    color: #fff;
    background: #2bd12b;
    border: 2px solid #2bd12b;
    border-radius: 13px;
    padding: 10px 33px;
    letter-spacing: 1px;
    margin: 0 10px;
    &:hover {
      background: none;
      color: #2bd12b;
      cursor: pointer;
      font-weight: 800;
    }
  }

  a {
    background: blue;
    color: #fff;
    border: 2px solid blue;
    border-radius: 13px;
    padding: 10px 33px;
    letter-spacing: 1px;
    margin: 0 10px;
    &:hover {
      background: none;
      color: blue;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #000;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 40px;
  background: #fff;
  border-radius: 3px;
  color: #000;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#d8d8d8")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }
`;

///

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: center;
`;

const ItemModal = styled.div`
  width: 345px;
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  height: 335px;

  background: #fff;
  border-radius: 12px;
  color: #000;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
