import axios from "axios";
import { useQuery } from "react-query";
import { apiRouteTest } from "../utils/api";
import { AccruedInterestType } from "../utils/commonTypes";

function useGetAccruedInterest() {
  const { data, isLoading } = useQuery(["getAccruedInterest"], () =>
    axios.get<AccruedInterestType[]>(`${apiRouteTest}/accrued-interest/`),
  );

  return { data, isLoading };
}

export { useGetAccruedInterest };
