import axios from "axios";
import { useQuery } from "react-query";
import { TableDepreciationType } from "../components/common/Tables/DepreciationAmortization/DepreciationAmortizationTable";
import { apiRouteTest } from "../utils/api";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

function useGetDepreciation() {
  const { data, isLoading } = useQuery(["getDepreciation"], () =>
    axios.get<TableDepreciationType>(
      `${apiRouteTest}/depreciation-amortization/`,
    ),
  );

  return { data, isLoading };
}

export { useGetDepreciation };
