import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";

type AccruedInterestType = {
  invoice_date: string;
  periods: number;
  invoice_total: number;
  client_id: number;
  customer: string;
};

function usePostDeferredRevenue(onCloseModal: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: AccruedInterestType) =>
      axios.post(`${apiRouteTest}/deferred-revenue/`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getDeferredRevenue");
        onCloseModal();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostDeferredRevenue };
