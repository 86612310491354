import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/index.css";
import { PageRouter } from "./components/PageRouter";
import { queryClient } from "./utils/api";

function App() {
  return (
    <div className="App">
      <Router>
        <QueryClientProvider client={queryClient}>
          <PageRouter />
        </QueryClientProvider>
      </Router>
    </div>
  );
}

export default App;
