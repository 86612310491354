import axios from "axios";
import { QueryClient } from "react-query";

export const apiRoute = ` https://LINK_FOR_BACKEND_HERE.COM${process.env.REACT_APP_API_ROUTE}`;
export const apiRouteTest = `https://ledgerbe.herokuapp.com`
export const envGlob = process.env.NODE_ENV;



export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});


export default axios.create({
  baseURL: "https://LINK_FOR_BACKEND_HERE.COM",
  responseType: "json",
});
