import axios from "axios";
import { useQuery } from "react-query";
import { apiRouteTest } from "../utils/api";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

type GetClientsType = {
  created_at: string;
  id: number;
  it_id: string;
  name: string;
  updated_at: string;
};

function useGetCategory() {
  const { data, isLoading } = useQuery(["getCategory"], () =>
    axios.get<GetClientsType[]>(`${apiRouteTest}/categories/`),
  );

  return { data, isLoading };
}

export { useGetCategory };
