import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

type AccruedInterestType = {
  access_token: string;
};

function usePostAccruedInterestPush() {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: AccruedInterestType) =>
      axios.post(`${apiRouteTest}/accrued-interest-push/`, data),
    {
      onSuccess: () => {
        context?.setMessage("Successfully processed");
        queryClient.invalidateQueries("getAccruedInterest");
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostAccruedInterestPush };
