import { Formik } from "formik";
import styled from "styled-components";
import { useDelFieldTable } from "../../../../hooks/useDelFieldTable";
import { CustomLoader } from "../../../Loader";

type PropsType = {
  onClose: () => void;
  type:
    | "deferred-revenue"
    | "accrued-interest"
    | "depreciation-amortization"
    | "prepaid-expenses";
  id: number;
};

export function ModalAcceptDelField({ onClose, type, id }: PropsType) {
  const { mutate: delUser, isLoading } = useDelFieldTable(onClose);
  return (
    <ModalContent>
      <h3>Remove item</h3>
      <h4>Are you sure you want to delete the field?</h4>
      <Formik
        initialValues={{
          id,
        }}
        onSubmit={(values, { setSubmitting }) => {
          delUser({
            id,
            type,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              {isLoading ? (
                <CustomLoader size={40} />
              ) : (
                <ModalButton disabled={isLoading}>
                  <button type="submit">Delete</button>
                  <button type="button" onClick={() => onClose()}>
                    Close
                  </button>
                </ModalButton>
              )}
            </form>
          </ItemModal>
        )}
      </Formik>
      <CloseMod onClick={() => onClose()}>x</CloseMod>
    </ModalContent>
  );
}

const CloseMod = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #616d7a;
  font-size: 20px;
  :hover {
    cursor: pointer;
  }
`;

const ModalButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  margin-top: 34px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;
    margin: 0 10px;

    ${props => (props.disabled ? `opacity: 0.5; cursor: no-drop` : ``)};

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 430px;
  height: 300px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > h4 {
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    margin-top: 65px;
    font-weight: 100;
  }

  > img {
    margin-top: 12px;
  }
`;
