import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest, queryClient } from "../utils/api";
import { Context } from "../utils/commonFunctions";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

export type PrepaidExpensesType = {
  category: number;
  purchase_date: string;
  periods: number;
  purchase_price: number;
  client_id: number;
};

function usePostPrepaidExpenses(onClose: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: PrepaidExpensesType) =>
      axios.post(`${apiRouteTest}/prepaid-expenses/`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getPrepaidExpenses");
        onClose();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostPrepaidExpenses };
