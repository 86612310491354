import axios from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest } from "../utils/api";
import { Context } from "../utils/commonFunctions";

type MutateDataType = {
  client_secret: string;
  client_id: string;
  user_id: number;
};

type RespDataType = {
  client_id: string;
  client_secret: string;
  created_at: string;
  id: number;
  oauth_url: string;
  updated_at: string;
};

function usePostConnectToQBO(onCloseModal: () => void) {
  const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data: MutateDataType) =>
      axios.post<RespDataType>(`${apiRouteTest}/oauth/`, data),
    {
      onSuccess: (res, rej) => {
        localStorage.setItem("oauth_url", res.data.oauth_url);
        localStorage.setItem("client_secret", rej.client_secret);
        localStorage.setItem("client_id", rej.client_id);
        window.open(res.data.oauth_url, "_blank");
        onCloseModal();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data };
}

export { usePostConnectToQBO };
