import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { HomePage } from "../../pages/HomePage";
import { MainPage } from "../../pages/MainPage";
import { Context } from "../../utils/commonFunctions";
import { AccruedInterestTable } from "../common/Tables/AccruedInterestTable";
import { DeferredRevenueTable } from "../common/Tables/DeferredRevenueTable";
import { DepreciationAmortizationTable } from "../common/Tables/DepreciationAmortization";
import { PrepaidExpensesTable } from "../common/Tables/PrepaidExpensesTable";
import { SnackBar } from "../SnackBar";

export const PageRouter = () => {
  const { data: authToken } = useCheckAuth();

  const hours = 1;
  const now = new Date().getTime();
  const setupTime = localStorage.getItem("setupTime");
  if (setupTime == null) {
    localStorage.setItem("setupTime", String(now));
  } else {
    if (now - Number(setupTime) > hours * 60 * 60 * 1000) {
      localStorage.clear();
    }
  }

  const [message, setMessage] = useState<string>("");

  return (
    <Switch>
      <Context.Provider value={{ setMessage }}>
        <Page>
          {authToken === null && <Redirect to="/" />}
          <Route exact path={"/"} component={HomePage} />
          <Route exact path={"/home"} component={MainPage} />

          <Route
            exact
            path={"/home/prepaid/"}
            render={() => (
              <PrepaidExpensesTable
                now={now}
                setupTime={Number(setupTime) || 0}
              />
            )}
          />

          <Route
            exact
            path={"/home/deferred/"}
            render={() => (
              <DeferredRevenueTable
                now={now}
                setupTime={Number(setupTime) || 0}
              />
            )}
          />

          <Route
            exact
            path={"/home/accured/"}
            render={() => (
              <AccruedInterestTable
                now={now}
                setupTime={Number(setupTime) || 0}
              />
            )}
          />

          <Route
            exact
            path={"/home/deprication/"}
            render={() => (
              <DepreciationAmortizationTable
                now={now}
                setupTime={Number(setupTime) || 0}
              />
            )}
          />
        </Page>
        {message && <SnackBar text={message} onClose={() => setMessage("")} />}
      </Context.Provider>
    </Switch>
  );
};

const Page = styled.div``;
