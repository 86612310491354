import { Formik } from "formik";
import styled from "styled-components";
import validationSingUp from "./validationSingUp";
import { useRegNewUser } from "../../../../hooks/useRegNewUser";
import { CustomLoader } from "../../../Loader";

type PropsModal = {
  closeModal: () => void;
};

export function ModalSingUp({ closeModal }: PropsModal) {
  const { mutate: registration, isLoading } = useRegNewUser(closeModal);

  return (
    <ModalContent>
      <h3>Sign Up</h3>
      <Formik
        initialValues={{
          email: "",
          password: "",
          username: "",
          business_name: "",
          confirmPassword: "",
        }}
        validationSchema={validationSingUp}
        onSubmit={(values, { setSubmitting }) => {
          registration({
            confirmPassword: values.confirmPassword,
            email: values.email,
            password: values.password,
            username: values.username,
            user_profile: {
              business_name: values.business_name,
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Name</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter name"
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  error={!!errors.username && touched.username}
                />
                <ErrorText>{errors.username}</ErrorText>
              </InputWrapperModalToken>

              <TitleIpnut>Business name</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter business name"
                  type="text"
                  name="business_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.business_name}
                  error={!!errors.business_name && touched.business_name}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Email</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Name@company.com"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={!!errors.email && touched.email}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Password</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter password, min 8 symbols"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={!!errors.password && touched.password}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Confirm password</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter password"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  error={!!errors.confirmPassword && touched.confirmPassword}
                />
              </InputWrapperModalToken>

              <ModalButton>
                {isLoading ? (
                  <CustomLoader size={40} />
                ) : (
                  <button type="submit">Login</button>
                )}
              </ModalButton>
            </form>
          </ItemModal>
        )}
      </Formik>
    </ModalContent>
  );
}

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 44px;
  background: #161d24;
  border-radius: 6px;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }
`;

///

const ErrorText = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  color: red;
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
  position: relative;
`;

const ModalButton = styled.div`
  display: flex;
  margin-top: 34px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 415px;
  height: 610px;
  overflow-y: auto;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
