import { Formik } from "formik";
import styled from "styled-components";
import validationModalAddUserDeferredRevenue from "./validationModalAddUserDeferredRevenue";
import { CustomLoader } from "../../../Loader";
import { useCheckAuth } from "../../../../hooks/useCheckAuth";
import { useDecodeJwt } from "../../../../hooks/useDecodeJwt";
import { usePutDeferredRevenue } from "../../../../hooks/usePutDeferredRevenue";

type PropsType = {
  onClose: () => void;
  invoice_date: string;
  periods: number;
  invoice_total: number;
  client_id: number;
  customer: string;
  id: number;
};

export function ModalUpdateDeferredRevenue({
  onClose,
  client_id,
  customer,
  id,
  invoice_date,
  invoice_total,
  periods,
}: PropsType) {
  const { data: authToken } = useCheckAuth();
  const { data: userData } = useDecodeJwt(authToken || "");

  const { mutate: addUserDeferredRevenue, isLoading } =
    usePutDeferredRevenue(onClose);

  return (
    <ModalContent>
      <h3>Add new item</h3>
      <Formik
        initialValues={{
          invoice_date,
          periods,
          invoice_total,
          customer,
        }}
        validationSchema={validationModalAddUserDeferredRevenue}
        onSubmit={(values, { setSubmitting }) => {
          addUserDeferredRevenue({
            invoice_date: values.invoice_date,
            periods: +values.periods,
            invoice_total: +values.invoice_total,
            client_id: userData?.user_id || 0,
            customer: values.customer,
            id,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <ItemModal>
            <form onSubmit={handleSubmit}>
              <TitleIpnut>Customer</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter customer"
                  type="text"
                  name="customer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.customer}
                  error={!!errors.customer && touched.customer}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Invoice date</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="YYYY-MM-DD"
                  type="date"
                  name="invoice_date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.invoice_date}
                  error={!!errors.invoice_date && touched.invoice_date}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Periods</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter periods"
                  type="text"
                  name="periods"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.periods}
                  error={!!errors.periods && touched.periods}
                />
              </InputWrapperModalToken>

              <TitleIpnut>Invoice total</TitleIpnut>
              <InputWrapperModalToken>
                <InputModal
                  placeholder="Enter invoice total"
                  type="text"
                  name="invoice_total"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.invoice_total}
                  error={!!errors.invoice_total && touched.invoice_total}
                />
              </InputWrapperModalToken>

              {isLoading ? (
                <CustomLoader size={40} />
              ) : (
                <ModalButton disabled={isLoading}>
                  <button type="submit">Confirm</button>
                </ModalButton>
              )}
            </form>
          </ItemModal>
        )}
      </Formik>
      <CloseMod onClick={() => onClose()}>x</CloseMod>
    </ModalContent>
  );
}

const CloseMod = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #616d7a;
  font-size: 20px;
  :hover {
    cursor: pointer;
  }
`;

const TitleIpnut = styled.h6`
  margin-top: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #616d7a;
`;

const InputModal = styled.input<{
  error?: boolean;
}>`
  width: 317px;
  height: 44px;
  background: #161d24;
  border-radius: 6px;
  border: 1px solid ${props => (props.error ? "#ff0000" : "#161d24")};
  font-size: 14px;
  line-height: 17px;
  padding: 0 12px;
  color: #ffffff !important;
  ::-webkit-input-placeholder {
    color: #464c50;
  }
  ::-moz-placeholder {
    color: #464c50;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
    :hover {
      cursor: pointer;
    }
  }
`;

const InputWrapperModalToken = styled.div`
  margin-top: 9px;
`;

const ModalButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  margin-top: 34px;
  justify-content: center;

  button {
    color: #fff;
    background: #0abc0a;
    border: 2px solid #0abc0a;
    border-radius: 13px;
    padding: 10px 60px;
    letter-spacing: 1px;

    ${props => (props.disabled ? `opacity: 0.5; cursor: no-drop` : ``)};

    &:hover {
      background: none;
      color: #0abc0a;
      cursor: pointer;
      font-weight: 800;
    }
  }
`;

const ItemModal = styled.div`
  position: relative;
  text-align: left;

  > h6 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #616d7a;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 430px;
  height: 520px;

  background: linear-gradient(45deg, #1f2933 3%, #0e141a 3%);
  border-radius: 12px;
  color: #ffffff;
  text-align: center;

  > h3 {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-top: 32px;
  }

  > img {
    margin-top: 12px;
  }
`;
