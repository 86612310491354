import * as yup from "yup";

const schema = yup.object().shape({
    category: yup.number().required("Required field"),
    purchase_price: yup.number().required("Required field"),
    periods: yup.number().required("Required field"),
    purchase_date: yup.date().required("Required field"),

    creatdOn: yup.date().default(function () {
        return new Date()
    })
})

export default schema;