import axios from "axios";
import { useContext } from "react";
//import { useContext } from "react";
import { useMutation } from "react-query";
import { apiRouteTest } from "../utils/api";
import { Context } from "../utils/commonFunctions";
import { usePostAutoriz } from "./usePostAutoriz";
//import { Context } from "../utils/commonFunctions";

type MutateDataType = {
  email: string;
  password: string;
  username: string;
  user_profile: {
    business_name: string;
  };
  confirmPassword: string;
};

function useRegNewUser(closeModal: () => void) {
  const context = useContext(Context);
  const { mutate: autoriz, data: regData } = usePostAutoriz(closeModal);
  //const context = useContext(Context);
  const { mutate, isLoading, isSuccess, data, error } = useMutation(
    (data: MutateDataType) => axios.post(`${apiRouteTest}/auth/users/`, data),
    {
      onSuccess: (res, rej) => {
        autoriz({ email: rej.email, password: rej.password });
        regData && localStorage.setItem("authToken", regData.data.access);
        closeModal();
      },
      onError: () => {
        context?.setMessage("Error. Something went wrong");
      },
    },
  );

  return { mutate, isLoading, isSuccess, data, error };
}

export { useRegNewUser };
