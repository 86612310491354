import axios from "axios";
import { useQuery } from "react-query";
import { apiRouteTest } from "../utils/api";
//import authHeader from "../utils/auth-header";
//{  headers: authHeader(),}

type GetClientsType = {
  it_id: string;
  name: string;
  created_at: string;
  id: number;
  updated_at: string;
  category: number;
};

function useGetItems() {
  const { data, isLoading } = useQuery(["getItems"], () =>
    axios.get<GetClientsType[]>(`${apiRouteTest}/items/`),
  );

  return { data, isLoading };
}

export { useGetItems };
